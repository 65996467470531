/* =============
   General
============= */

html {
	position: relative;
	min-height: 100%;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: #495057;
}

.h1, h1 {
	font-size: 2.25rem;
}

.h2, h2 {
	font-size: 1.8rem;
}

.h3, h3 {
	font-size: 1.575rem;
}

.h4, h4 {
	font-size: 1.35rem;
}

.h5, h5 {
	font-size: 1.125rem;
}

.h6, h6 {
	font-size: .9rem;
}

a {
	text-decoration: none !important;
}

p {
	margin-bottom: 0;
}

label {
	font-weight: 500;
}

.blockquote {
	padding: 10px 20px;
	border-left: 4px solid #f6f6f6;
}

.blockquote-reverse {
	border-left: 0;
	border-right: 4px solid #f6f6f6;
	text-align: right;
}

@media (min-width: 1200px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
		max-width: 1140px;
	}
}

.row>* {
	position: relative;
}

label {
	margin-bottom: .5rem;
}

.form-control:disabled, .form-control[readonly] {
	cursor: not-allowed;
}

/*# sourceMappingURL=main.css.map */

.table thead th {
	vertical-align: middle;
	border-bottom: 2px solid #f8f8f8;
}

.table th {
	font-weight: 600;
	padding: 0.5rem 0.35rem;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #f8f9fa;
}

.table .table-light {
	color: #495057;
	border-color: #f5f6f8;
	background-color: #f8f9fa;
}

.table> :not(caption)>*>* {
	border-bottom-width: 0px;
	border-top-width: 1px;
}

.table> :not(:last-child)> :last-child>* {
	border-bottom-color: #f5f6f8;
}

.table-dark> :not(:last-child)> :last-child>* {
	border-bottom-color: tint-color(#343a40, 7.5%);
}

.table-nowrap th, .table-nowrap td {
	white-space: nowrap;
}

.table-centered td, .table-centered th {
	vertical-align: middle !important;
}

.table-card-list {
	border-collapse: separate;
	border-spacing: 0 12px;
}

.table-card-list tr {
	background-color: #fff;
}

.nav-link {
	color: #495057;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	color: #25B17B;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
	background: #fafbfc;
}

label {
	font-weight: 700;
	font-size: .8rem;
	color: rgba(0, 0, 0, .4);
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-bottom: 0.2rem;
	padding-left: 5px;
}

.form-group {
	margin-bottom: 0.5rem;
}

.form-switch-md {
	padding-left: 2.5rem;
	min-height: 24px;
	line-height: 24px;
}

.form-switch-lg {
	padding-left: 2.75rem;
	min-height: 28px;
	line-height: 28px;
}

.dropdown-toggle:after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-menu {
	box-shadow: 0 5px 25px 0 rgb(0 0 0 / 10%);
}

.modal-header {
	padding: 0.5rem 1rem;
}

.modal-title {
	font-weight: 700;
}

.dropdown-filter.show {
	width: 470px;
	padding: 1rem;
}

.btn-check:checked+.btn-primary:focus, .btn-check:active+.btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show>.btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
	box-shadow: none;
}

.btn-check:checked+.btn-outline-primary:focus, .btn-check:active+.btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
	box-shadow: none;
}

.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
	box-shadow: none;
}

/*# sourceMappingURL=main.css.map */