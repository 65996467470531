.form-check {
	position: relative;
}

.form-check-right {
	padding-left: 0;
	display: inline-block;
	padding-right: 1.5em;
}

.form-check-right .form-check-input {
	float: right;
	margin-left: 0;
	margin-right: -1.5em;
}

.form-check-right .form-check-label {
	display: block;
}

.form-checkbox-outline .form-check-input {
	border-width: 2px;
	background-color: #fff;
}

.form-checkbox-outline .form-check-input:active {
	-webkit-filter: none;
	filter: none;
}

.form-checkbox-outline .form-check-input:checked {
	background-color: #fff !important;
}

.form-checkbox-outline .form-check-input:checked[type=checkbox] {
	background-image: none;
}

.form-checkbox-outline .form-check-input:checked:after {
	position: absolute;
	content: '\F012C';
	font-family: "Material Design Icons";
	top: -4px !important;
	left: 1px;
	font-size: 16px;
}

.form-radio-outline .form-check-input {
	border-width: 2px;
	background-color: #fff;
}

.form-radio-outline .form-check-input:active {
	-webkit-filter: none;
	filter: none;
}

.form-radio-outline .form-check-input:checked {
	background-color: #fff !important;
}

.form-radio-outline .form-check-input:checked[type=checkbox] {
	background-image: none;
}

.form-radio-outline .form-check-input:checked:after {
	position: absolute;
	content: '\F0765';
	font-family: "Material Design Icons";
	top: 6px !important;
	left: 4px;
	font-size: 6px;
}

.form-check-primary .form-check-input:checked {
	background-color: #25B17B;
	border-color: #25B17B;
}

.form-radio-primary .form-check-input:checked {
	border-color: #25B17B;
	background-color: #25B17B;
}

.form-radio-primary .form-check-input:checked:after {
	color: #25B17B;
}

.form-check-secondary .form-check-input:checked {
	background-color: #74788d;
	border-color: #74788d;
}

.form-radio-secondary .form-check-input:checked {
	border-color: #74788d;
	background-color: #74788d;
}

.form-radio-secondary .form-check-input:checked:after {
	color: #74788d;
}

.form-check-success .form-check-input:checked {
	background-color: #34c38f;
	border-color: #34c38f;
}

.form-radio-success .form-check-input:checked {
	border-color: #34c38f;
	background-color: #34c38f;
}

.form-radio-success .form-check-input:checked:after {
	color: #34c38f;
}

.form-check-info .form-check-input:checked {
	background-color: #50a5f1;
	border-color: #50a5f1;
}

.form-radio-info .form-check-input:checked {
	border-color: #50a5f1;
	background-color: #50a5f1;
}

.form-radio-info .form-check-input:checked:after {
	color: #50a5f1;
}

.form-check-warning .form-check-input:checked {
	background-color: #f1b44c;
	border-color: #f1b44c;
}

.form-radio-warning .form-check-input:checked {
	border-color: #f1b44c;
	background-color: #f1b44c;
}

.form-radio-warning .form-check-input:checked:after {
	color: #f1b44c;
}

.form-check-danger .form-check-input:checked {
	background-color: #f46a6a;
	border-color: #f46a6a;
}

.form-radio-danger .form-check-input:checked {
	border-color: #f46a6a;
	background-color: #f46a6a;
}

.form-radio-danger .form-check-input:checked:after {
	color: #f46a6a;
}

.form-check-pink .form-check-input:checked {
	background-color: #e83e8c;
	border-color: #e83e8c;
}

.form-radio-pink .form-check-input:checked {
	border-color: #e83e8c;
	background-color: #e83e8c;
}

.form-radio-pink .form-check-input:checked:after {
	color: #e83e8c;
}

.form-check-light .form-check-input:checked {
	background-color: #f5f6f8;
	border-color: #f5f6f8;
}

.form-radio-light .form-check-input:checked {
	border-color: #f5f6f8;
	background-color: #f5f6f8;
}

.form-radio-light .form-check-input:checked:after {
	color: #f5f6f8;
}

.form-check-dark .form-check-input:checked {
	background-color: #343a40;
	border-color: #343a40;
}

.form-radio-dark .form-check-input:checked {
	border-color: #343a40;
	background-color: #343a40;
}

.form-radio-dark .form-check-input:checked:after {
	color: #343a40;
}

.form-check-purple .form-check-input:checked {
	background-color: #6f42c1;
	border-color: #6f42c1;
}

.form-radio-purple .form-check-input:checked {
	border-color: #6f42c1;
	background-color: #6f42c1;
}

.form-radio-purple .form-check-input:checked:after {
	color: #6f42c1;
}

.form-check, .form-check-input, .form-check-label {
	cursor: pointer;
	margin-bottom: 0;
}

.form-check {
	position: relative;
	text-align: left;
}

.form-switch-md {
	padding-left: 2.5rem;
	min-height: 24px;
	line-height: 24px;
}

.form-switch-md .form-check-input {
	width: 40px;
	height: 20px;
	left: -0.5rem;
	position: relative;
}

.form-switch-md .form-check-label {
	vertical-align: middle;
}

.form-switch-lg {
	padding-left: 2.75rem;
	min-height: 28px;
	line-height: 28px;
}

.form-switch-lg .form-check-input {
	width: 48px;
	height: 24px;
	left: -0.75rem;
	position: relative;
}

.input-group-text {
	margin-bottom: 0px;
}

.custom-control-label::before {
	background-color: #fff;
}

/*# sourceMappingURL=main.css.map */